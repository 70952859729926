import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../../../../services/api";
import { useState } from "react";

export function useCreateNews() {
  const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();
  const { data: categoryData, isLoading: categoryLoading } = useQuery(
    "categorias",
    async () => {
      const response = await api.get("/category");
      return response.data.res;
    }
  );

  const {
    mutate: createNews,
    isLoading: createNewsLoading,
    isError: createNewsError,
  } = useMutation(
    async (data) => {
      const response = await api.post("/news/nova", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progress) => {
          setProgress(Math.round((progress.loaded / progress.total) * 100));
        },
      });
      return response.data.res;
    },
    {
      retry: 1,
      retryDelay: 1000,
      onSuccess: () => {
        queryClient.invalidateQueries("news");
        window.location.href = "/admin/noticias/painel";
      },
      onError: (e) => {
        console.error(e);
        console.log("erro");
      },
    }
  );

  return {
    categoryData,
    categoryLoading,
    createNews,
    createNewsLoading,
    createNewsError,
    progress,
  };
}
