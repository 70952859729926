
import { useEffect, useState } from "react"
import api from "../../../../services/api"
import { linkArquivos } from "../../../../services/variables"
import diary from "../../../../styles/module/transparency/diary.module.css"
import BoxDetailDiary from "./BoxDetail"
import SubTitle from "../../others/title/SubTitle"

import Loading from "../../others/LoadingFull"

export default function ButtonsDiary(){
    

    const [showDetail,setShowDetail] = useState(false)
    const [removeLoading,setRemoveLoading] = useState(false)
    const [dataDetail,setDataDetail] = useState({
        NUMERO:'',
        DETALHES: '',
        DATA: '',
        CNPJ: '',
        ARQUIVO: '',
        COUNT:''
        
    })
    useEffect(()=>{
        api.get('/diary-official/show/last').then((result)=>{
            const newResult = result.data

            if(!newResult.err){
                setDataDetail({ 
                    NUMERO: newResult.res[0].NUMERO ,
                    DETALHES: newResult.res[0].DETALHES,
                    DATA: newResult.res[0].DATA,
                    CNPJ: newResult.res[0].CNPJ,
                    ARQUIVO: newResult.res[0].ARQUIVO,
                    APRESENTACAO: newResult.res[0].APRESENTACAO,
                    EXPEDIENTE: newResult.res[0].EXPEDIENTE,
                    EQUIPE: newResult.res[0].EQUIPE,
                    COUNT:newResult.countItems,
                })
                setRemoveLoading(true)

            }else{
            }
        }).catch((err)=>{
        setDataDetail({
            NUMERO:'',
            DETALHES: '',
            DATA: '',
            CNPJ: '',
            ARQUIVO: '',
            APRESENTACAO:'',
            EXPEDIENTE:'',
            EQUIPE: '',
            COUNT:''})
        }
        ).finally(()=>{

        })
    },[])

    const dateRender = (dateReq) => {
        const nameMonth = {
          0:'01',
          1:'02',
          2:'03',
          3:'04',
          4:'05',
          5:'06',
          6:'07',
          7:'08',
          8:'09',
          9:'10',
          10:'11',
          11:'12'
        }
        const date = new Date(dateReq);
        if(dateReq == ""){
            return ""
          }else{
        date.setDate(date.getDate() + 1);
      
        var day = date.getDate()
        if(date.getDate() < 10){
          var day = "0"+date.getDate()
        }
        const formatDate =  day  + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();
        return formatDate ;
    }
      };
    const handleChangeDetail = ()=>{
        
        setShowDetail(!showDetail)
    }

    return(
    <>
    {!removeLoading && <Loading/>}
    <div className={diary.group}>

    <div className={diary.card}>
    <div className={diary.titleTxt}>   
    <svg   className={diary.iconeCard} fill="currentColor" viewBox="0 0 16 16">
    <path d="M7.05 11.885c0 1.415-.548 2.206-1.524 2.206C4.548 14.09 4 13.3 4 11.885c0-1.412.548-2.203 1.526-2.203.976 0 1.524.79 1.524 2.203zm-1.524-1.612c-.542 0-.832.563-.832 1.612 0 .088.003.173.006.252l1.559-1.143c-.126-.474-.375-.72-.733-.72zm-.732 2.508c.126.472.372.718.732.718.54 0 .83-.563.83-1.614 0-.085-.003-.17-.006-.25l-1.556 1.146zm6.061.624V14h-3v-.595h1.181V10.5h-.05l-1.136.747v-.688l1.19-.786h.69v3.633h1.125z"/>
    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6.5v2z"/>
    </svg>
    <h6>
    Edição Atual
    </h6>
    </div>
    <div className={diary.bodyTxt}>
    <p>Edição: <b className={diary.numberDiary}>{dataDetail.NUMERO}</b></p>
    <p>Data: {dateRender(dataDetail.DATA)} </p>
    <p>Ações:
  
    <svg onClick={()=>handleChangeDetail()} title='Detalhes'  class={diary.iconeActions} viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </svg>
        
    <a href={linkArquivos+"/"+dataDetail.CNPJ+'/'+dataDetail.ARQUIVO} target="_blank" rel="noopener noreferrer" title='Baixar'>
    <svg class={diary.iconeActions} viewBox="0 0 16 16">
    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
    </svg>
    </a>
    </p>
    </div>
    </div>
    <div className={diary.card}>
    <div className={diary.titleTxt}>   
    <svg   fill="currentColor"  className={diary.iconeCard} viewBox="0 0 16 16">
    <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
    </svg>
    <h6>
    Edições Anteriores
    </h6>
    </div>
    <div className={diary.bodyTxt}>
    <p>Quantidade de Edições: {dataDetail.COUNT}</p>
    </div>
    </div>
    <div className={diary.card}>
        <div className={diary.titleTxt}>   
    <svg  className={diary.iconeCard}  fill="currentColor" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
    </svg>
    <h6>
    Informações
    </h6>
    </div>
    <div className={diary.bodyTxt}>
    <p>
        <svg width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
        </svg>
        <a href="/diario-oficial/lei-de-criacao-do-diario"> Lei de Criação do Diário Oficial</a>
    </p>
    <p>
    <svg width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
        </svg>
        <a href="/diario-oficial/ato-de-nomeacao-do-responsavel"><i class="bi bi-file-earmark-text"></i>Ato de Nomeação de Pessoa Responsável</a>
    </p>
   
    

    </div>
    </div>
    </div>

    <SubTitle subTitle={'Apresentação'} border={true}/>
    {dataDetail.APRESENTACAO != '' ?
    <div className={diary.inforDiaryTxtSection} dangerouslySetInnerHTML={{ __html: dataDetail.APRESENTACAO }}></div>
    : <><div className={diary.inforDiaryTxtSection}><scan className={diary.txtNone}>Nenhum resultado encontrado</scan></div></>}

        
    <SubTitle subTitle={'Expediente'} border={true}/>
    {dataDetail.EXPEDIENTE != '' ?
    <div className={diary.inforDiaryTxtSection} dangerouslySetInnerHTML={{ __html: dataDetail.EXPEDIENTE }}></div>
    : <><div className={diary.inforDiaryTxtSection}><scan className={diary.txtNone}>Nenhum resultado encontrado</scan></div></>}

    <SubTitle subTitle={'Equipe'} border={true}/>
    {dataDetail.EQUIPE != '' ?
    <div className={diary.inforDiaryTxtSection} dangerouslySetInnerHTML={{ __html: dataDetail.EQUIPE }}></div>
    : <><div className={diary.inforDiaryTxtSection}><scan className={diary.txtNone}>Nenhum resultado encontrado</scan></div></>}

    {showDetail && 
    <BoxDetailDiary 
    handle={handleChangeDetail} 
    data={{title:dataDetail.NUMERO,body:dataDetail.DETALHES}} />
    }

    </>)
}