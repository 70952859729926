import styles from "../../../styles/module/news.module.css";
import React from "react";
import api from "../../../services/api";
import CarouselIMG from "../img/CarouselIMG";
import Loading from "../others/Loading";
import { useQuery } from "react-query";
import { imagemURL, urlEmDeploy } from "../../../services/variables";

export default function News(props) {
  const idNoticia = props.idNoticias;

  const { data, isLoading, isError } = useQuery(
    `news-${idNoticia}`,
    async () => {
      const response = await api.post(`/news/unique/${idNoticia}`);
      return response.data.res;
    },
    { staleTime: 5 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>Error loading news...</p>;
  }

  const generateWhatsAppLink = (title) => {
    const baseUrl = `https://wa.me/?text=`;
    const message = encodeURIComponent(
      `${title} - Confira a notícia completa em: ${urlEmDeploy}noticia/${idNoticia}`
    );
    return baseUrl + message;
  };

  return (
    <div className={styles.container}>
      {data &&
        data.map((news) => (
          <div key={news.ID} className={styles.newsItem}>
            <div className={styles.header}>
              <img alt="notícias" src="/icons/seta-direita.svg" />
              <h2 className={styles.title}>{news.TITULO}</h2>
            </div>
            <div className={styles.metaInfo}>
              <p className={styles.category}>Categoria: {news.CATEGORIA}</p>
              <p className={styles.date}>
                Data: {new Date(news.DATA_POST).toLocaleDateString("pt-br")}
              </p>
              <p className={styles.views}>{news.VISUALIZACAO} visualizações</p>
            </div>
            <div className={styles.imageWrapper}>
              <img
                width={360}
                height={360}
                alt="imagem da notícia"
                src={
                  news.IMG === "logo.jpg"
                    ? "/imagens/logo.png"
                    : imagemURL + news.IMG
                }
              />
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: news.CORPO_TEXTO }}
            />
            {news.VIDEO && (
              <div className={styles.videoWrapper}>
                <iframe
                  width="560"
                  height="315"
                  className={styles.video}
                  src={news.VIDEO}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            )}
            <CarouselIMG img={news.MULTIPLE_IMG} />
            <div className={styles.shareSection}>
              <a
                href={generateWhatsAppLink(news.TITULO)}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.whatsappButton}
              >
                <img src="/icons/whatsapp.svg" alt="WhatsApp" />
                Compartilhar
              </a>
            </div>
          </div>
        ))}
    </div>
  );
}
