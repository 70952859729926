import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {NomeTipoPortal,NomePortal} from '../../../../services/variables'
import {logoPortalBase64} from '../../../../services/logoBase64'

export default  function PDFgenerate(props) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


   
    
    
    const data = props
    
    const arrayData2 = []
    data.map((d, i) => {
        arrayData2.push([{text: d.DATA,fontSize: 10}, {text: d.NUMERO,fontSize: 10},{text: d.TIPO,fontSize: 10}])
    })
 
    const reportTitle = [
        {

        }
    ]
    const details = [
        {image: logoPortalBase64,
	    width: 50,height: 50,alignment: 'center'},	
        {text: `${NomeTipoPortal} de ${NomePortal}\n\n`,fontSize: 14,alignment: 'center'},
        {
			style: 'tableExample',
			table: {
				headerRows: 1,
				// dontBreakRows: true,
				// keepWithHeaderRows: 1,
				body: arrayData2
			}
		},
    ]
    // const footer = [{text: `Arquivo gerado no portal: ${new Date().toLocaleString()} \n`,fontSize: 8, alignment: 'center'}]
    const footer = [{text: ``}]
    
    const docDefinitions = {
        pageSize: 'A4',
        pageMargins:[15,50,15,40],
        headers : [reportTitle],
        content: [details],
        footer: [footer],
        
    }
 pdfMake.createPdf(docDefinitions).open()//.print()//.download()
    
}