import { NomePortal, NomeTipoPortal } from "../../../src/services/variables";
import "../../styles/navbar.css";
export default function MenuAdmin() {
  return (
    <>
      <section className="div-navbar">
        <a href="/">
          <div className="nav-logo-no-animation">
            <img
              alt="Logo Portal"
              src="/imagens/logo.png"
              width="50"
              height="50"
              className="logo-top"
            />
            <div className="nav-name">
              <h3 className="Title-Navbar">{NomePortal}</h3>
              <hr className="Title-Navbar line"></hr>
              <h5 className="Title-Navbar">{NomeTipoPortal}</h5>
            </div>
          </div>
        </a>
      </section>
    </>
  );
}
