import api from "../../../services/api";
import "../../../styles/OfficeInfo.css";
import { TipoPortal } from "../../../services/variables";
import Loading from "../others/Loading";
import CardInforOffice from "./CardOffice";
import SubTitle from "../others/title/SubTitle";
import TitleMain from "../others/title/Title";
import { useQuery } from "react-query";

export default function OfficeInfo() {
  const { data, isLoading } = useQuery(
    "officeInfo",
    async () => {
      const response = await api.get(`/competencias/show/competencias`);
      return response.data.res;
    },
    { cacheTime: 60 * 1000 * 1000 * 1, staleTime: 60 * 1000 * 1000 * 1 } // cache de 12
  );
  return (
    <div>
      <TitleMain
        route={
          TipoPortal == 3
            ? "Início / Informações Institucionais / Registro das Competências"
            : "Início / Vereadores  / Registro das Competências"
        }
        title={"Registro das Competências"}
      />
      <SubTitle subTitle={TipoPortal == 3 ? "Gabinete" : "Mesa Diretora"} />
      {isLoading && <Loading />}
      <div className="cards-container">
        {!isLoading &&
          data &&
          data?.Gabinete?.map((item) => (
            <CardInforOffice data={item} key={item.ID} />
          ))}
      </div>
      <SubTitle subTitle={TipoPortal == 3 ? "Secretarias" : "Parlamentares"} />
      {isLoading && <Loading />}
      <div className="cards-container">
        {!isLoading &&
          data &&
          data.Outros?.map((item) => (
            <CardInforOffice data={item} key={item.ID} />
          ))}
      </div>
    </div>
  );
}
