import api from "../../../../services/api";
import { useQuery } from "react-query";
import exportFromJSON from "export-from-json";
import { NomePortal } from "../../../../services/variables";

// REQUISIÇÕES
async function fetchAtos(ato) {
  const res = await api.post("/atosAdministrativos/list", {
    type: ato,
  });
  return res.data;
}

async function fetchSacops(type) {
  const res = await api.get(`/sacop/list/all/${type}`);
  return res.data.res;
}

export function useGetAtos(ato) {
  return useQuery(`adm-${ato}`, () => fetchAtos(ato));
}

export function useGetSacops(type) {
  return useQuery(`sacop-${type}`, () => fetchSacops(type));
}

// METOTOS DE DOWNLOADS
export const JSONexports = ({ data, select }) => {
  const fileName = `${select} ${NomePortal}`;
  const exportType = exportFromJSON.types.json;
  exportFromJSON({ data: data, fileName, exportType });
};

export const XLSexports = ({ data, select }) => {
  const fileName = `${select} ${NomePortal}`;
  const exportType = exportFromJSON.types.xls;
  exportFromJSON({ data: data, fileName, exportType });
};

export const CSVexports = ({ data, select }) => {
  const fileName = `${select} ${NomePortal}`;
  const exportType = exportFromJSON.types.csv;
  exportFromJSON({ data: data, fileName, exportType });
};
