import { useState } from "react";
import "../../../styles/admin/login.css";
import Loading from "../../public/others/LoadingFull";
import api from "../../../services/api";

export default function CreatedUser() {
  const [email, setEmail] = useState(""); //nome
  const [name, setName] = useState(""); //email
  const [password, setPassword] = useState(""); //password
  const [removeLoading, setRemoveLoading] = useState(true); //loading

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name != "" && email != "" && password != "") {
      setRemoveLoading(false);
      api
        .post("/user/register", {
          nome: name,
          email: email,
          senha: password,
        })
        .then((result) => {
          console.log(result);
          if (result.data.err) {
            alert("Erro, Perfil não foi cadastrado!!!");
            setRemoveLoading(true);
          } else {
            alert(
              "Entre em contato com a sua instituição, Perfil está em analise!!!"
            );

            setRemoveLoading(true);
          }
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      {!removeLoading && <Loading />}
      <div className="container-admin-login">
        <form onSubmit={handleSubmit} className="form-admin-login">
          <div className="form-container">
            <h3 className="title-login">Crie uma conta</h3>
            <div className="img-login">
              <img src="/imagens/logo-work.png" alt="" />
              <img src="/imagens/logo.png" alt="" />
            </div>

            <label className="form-login">
              {/* nome */}
              <input
                type="text"
                name="title"
                className="form-input-login"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="underline"></div>
            </label>
            <label className="form-login">
              {/* Email */}
              <input
                type="email"
                name="title"
                className="form-input-login"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu email"
              />
              <div className="underline"></div>
            </label>
            <label className="form-login">
              {/* Senha */}
              <input
                type="password"
                name="title"
                className="form-input-login"
                placeholder="Digite uma senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="underline"></div>
            </label>
            <input
              type="submit"
              value="Cadastrar"
              className="button-submit-login"
            />
            <a href="/admin/login" className="link-user-login">
              Já tenho acesso
            </a>
          </div>
        </form>
      </div>
    </>
  );
}
