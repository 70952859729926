import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {NomeTipoPortal,NomePortal} from '../../../../services/variables'
import {logoPortalBase64} from '../../../../services/logoBase64'

export default  function PDFgenerate(props) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


   
    
    
    const data = props
    // const arrayData2 = []
     const arrayData2 = [
        [
            {text: 'NUMERO DO PEDIDO',bold: true,fontSize: 9}, 
            {text: 'DATA ENVIO PEDIDO',bold: true,fontSize: 9},
            {text:'DATA ENCERRAMENTO PEDIDO',bold: true,fontSize: 9},
            {text: 'RESUMO',bold: true,fontSize: 9},
            {text: 'DETALHES',bold: true,fontSize: 9},
            {text: 'RESPOSTA',bold: true,fontSize: 9},
            {text: 'DATA DA RESPOSTA',bold: true,fontSize: 9},
            {text: 'DATA PRAZO PARA RESPOSTA',bold: true,fontSize: 9},
            {text: 'RECURSO',bold: true,fontSize: 9},
            {text: 'DATA PRAZO PARA RECURSO',bold: true,fontSize: 9},
        ]
    ]


    data.map((d, i) => {
        arrayData2.push(
            [
            {text: d['NUMERO_PEDIDO'],fontSize: 8}, 
            {text: d['DATA_PEDIDO'],fontSize: 8},
            {text: d['DATA_ENCERRAMENTO'],fontSize: 8},
            {text: d['RESUMO'],fontSize: 8},
            {text: d['DETALHE'],fontSize: 8},
            {text: d['RESPOSTA'],fontSize: 8},
            {text: d['DATA_RESPOSTA'],fontSize: 8},
            {text: d['DATA_PRAZO'],fontSize: 8},
            {text: d['RECURSO'],fontSize: 8},
            {text: d['DATA_PRAZO_RECURSO'],fontSize: 8},])
    })
 
    const reportTitle = [
        {

        }
    ]
    const details = [
        {image: logoPortalBase64,
	    width: 50,height: 50,alignment: 'center'},	
        {text: `${NomeTipoPortal} de ${NomePortal}\n\n`,fontSize: 14,alignment: 'center'},
        {
			style: 'tableExample',
			table: {
				headerRows: 1,
				// dontBreakRows: true,
				// keepWithHeaderRows: 1,
				body: arrayData2
			}
		},
    ]
    // const footer = [{text: `Arquivo gerado no portal: ${new Date().toLocaleString()} \n`,fontSize: 8, alignment: 'center'}]
    const footer = [{text: ``}]
    
    const docDefinitions = {
        pageSize: 'A3',
        pageMargins:[15,50,15,40],
        headers : [reportTitle],
        content: [details],
        footer: [footer],
        
    }
 pdfMake.createPdf(docDefinitions).open()//.print()//.download()
    
}