import styles from "../../../../../../styles/module/transparency/sacop.module.css";
import Loading from "../../../../others/LoadingFull";
import api from "../../../../../../services/api";
import { useEffect, useState } from "react";
import TableFiles from "./TableFiles";

export default function TableInforSacop(props) {
  // const idSacop = props.name
  const idSacop = props.id;

  const [removeLoading, setRemoveLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [sacop, setSacop] = useState([]);
  const [file, setFiles] = useState([]);
  const [cnpj, setCNPJ] = useState("");
  const [filesBACKUP, setFIlesBACKUP] = useState([]);

  const [dataPortariaTodos, setSacopTodos] = useState([]);

  useEffect(() => {
    console.log(Math.round(window.innerWidth));

    api
      .get(`/sacop/list/infor/files/${idSacop}`)
      .then((res) => {
        if (res.data.err !== true) {
          setSacop(res.data.res);
          // setFiles(res.data.res['FILES'])
          // setFIlesBACKUP(res.data.res['FILES'])
          setCNPJ(
            res.data.res["CNPJ"]
              .replace("/", "")
              .replace(".", "")
              .replace(".", "")
              .replace("-", "")
          );
          setRemoveLoading(true);

          const result = res.data.res["FILES"];
          // console.log(result)
          if (result.length != 0) {
            const newResult = formatString(result, Object.keys(result[0]));
            setFiles(newResult);
            setFIlesBACKUP(newResult);
          } else {
            setFiles(res.data.res["FILES"]);
            setFIlesBACKUP(res.data.res["FILES"]);
          }
        }
      })
      .catch((err) => {});
  }, []);

  const formatString = (data, keys) => {
    var resultString = [];

    for (const i in data) {
      const newData = keys
        .map((key) => {
          let value = data[i][key];
          if (key.toLocaleUpperCase().includes("DATA")) {
            // se for data
            const newFormatDate = dateRender(value);
            value = newFormatDate;
          }

          if (key.toLocaleUpperCase().includes("CNPJ")) {
            // se for cnpj

            if (value != undefined || value != null) {
              value = value.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              );
            } else {
              value = "";
            }
          }
          if (
            typeof value != "string" &&
            key.toLocaleUpperCase().includes("VALOR")
          ) {
            //se não for string

            value = moneyFrom(value);
          } else if (typeof value != "string") {
            if (value != undefined || value != null) {
              value = value.toString();
            } else {
              value = "";
            }
          }
          return { [key]: value };
        })
        .reduce((prev, next) => {
          return { ...prev, ...next };
        }, {});
      resultString.push(newData);
    }
    return resultString;
  };

  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();

      return formatDate;
    }
  };

  const moneyFrom = (money) => {
    if (money == "" || typeof money == String) {
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };

  return (
    <>
      {!removeLoading && <Loading />}
      <section className={styles.containerInfor}>
        <div className="section-title-portarias">
          <h4>Detalhes</h4>
        </div>

        <div className={styles.rowInfor}>
          <div>
            <b>Número Processo:</b>
            <br />
            {sacop.NUMEROPROCESSO}
          </div>
          <div>
            <b>Número Instrumento:</b>
            <br />
            {sacop.NUMEROINSTRUMENTO}{" "}
          </div>
          <div>
            <b>Exercício:</b>
            <br />
            {sacop.EXERCICIO}
          </div>
        </div>
        <div className={styles.rowInfor}>
          <div>
            <b>Unidade:</b>
            <br /> {sacop.UNIDADE_NOME}
          </div>
          <div>
            <b>Modalidade:</b>
            <br /> {sacop.MODALIDADE_NOME}
          </div>
          <div>
            <b>Regime Execução Obra:</b>
            <br /> {sacop.REGIME_OBRA_NOME}
          </div>
        </div>

        <div className={styles.rowInfor}>
          <div>
            <b>Nome Autoridade:</b>
            <br />
            {sacop.NOMEAUTORIDADE}
          </div>
          <div>
            <b>Cargo Autoridade:</b> <br />
            {sacop.CARGOAUTORIDADE}
          </div>
          <div>
            <b>CPF Autoridade:</b>
            <br /> {sacop.CPFAUTORIDADE}
          </div>
        </div>
        <div className={styles.rowInfor}>
          <div>
            <b>Nome Homologador:</b> <br />
            {sacop.NOMEHOMOLOGADOR}
          </div>
          <div>
            <b>Data Homologação:</b>
            <br />
            {sacop.DATAHOMOLOGACAO != undefined ? (
              <>{dateRender(sacop.DATAHOMOLOGACAO)}</>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.rowInforItem1}>
          {/*Objeto  */}
          <div>
            <b>Objeto:</b> <br /> {sacop.OBJETO}
          </div>
        </div>
        <div className={styles.rowInfor}>
          {/*Objeto  */}
          <div>
            <b>Tipo Objeto:</b>
            <br />
            {sacop.TIPO_OBJETO_NOME}
          </div>
        </div>
        <div className={styles.rowInforItem1}>
          <div>
            <b>Veículo de Divulgação:</b>
            <br />
            {sacop.VEICULODIVULGACAO}
          </div>
        </div>
        <div className={styles.rowInfor}>
          <div>
            <b>Data Publicação:</b>
            <br />
            {sacop.DATAPUBLICACAO != undefined ? (
              <>{dateRender(sacop.DATAPUBLICACAO)}</>
            ) : (
              <></>
            )}
          </div>
          {/* { != undefined ?<>{dateRender()}</>:<></>} */}
          <div>
            <b>Data Abertura:</b>
            <br />
            {sacop.DATAABERTURA != undefined ? (
              <>{dateRender(sacop.DATAABERTURA)}</>
            ) : (
              <></>
            )}
          </div>
          <div>
            <b>Data Publicação Resultado:</b>
            <br />
            {sacop.DATAPUBLICACAORESULTADO != undefined ? (
              <>{dateRender(sacop.DATAPUBLICACAORESULTADO)}</>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.rowInfor}>
          <div>
            <b>Tipo:</b>
            <br />
            {sacop.TIPO_LICITACAO_NOME}
          </div>
          <div>
            <b>Procedimento:</b>
            <br />
            {sacop.PROCESSO_LICITACAO_NOME}
          </div>
          <div>
            <b>Status:</b>
            <br />
            {sacop.STATUS_LICITACAO_NOME}
          </div>
        </div>

        <div className={styles.rowInfor}>
          <div>
            <b>Valor Total da Despesa:</b>
            <br />
            {sacop.VALORTOTALDESPESA != undefined ? (
              <>{moneyFrom(sacop.VALORTOTALDESPESA)}</>
            ) : (
              <></>
            )}
          </div>
          <div>
            <b>Valor da Dotação:</b>
            <br />
            {sacop.VALORDOTACAO != undefined ? (
              <>{moneyFrom(sacop.VALORDOTACAO)}</>
            ) : (
              <></>
            )}
          </div>
          <div>
            <b>Valor Adjudicado:</b>
            <br />
            {sacop.VALORGLOBALADJUDICADO != undefined ? (
              <>{moneyFrom(sacop.VALORGLOBALADJUDICADO)}</>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={styles.rowInfor}>
          <div>
            <b>Dotação Reforçada:</b>
            <br />{" "}
            {sacop.DOTACAOREFORCADA != undefined ? (
              <>{sacop.DOTACAOREFORCADA != "S" ? <>Não</> : <>Sim</>}</>
            ) : (
              <></>
            )}
          </div>
          <div></div>
          <div>
            <b>Corona Vírus:</b>
            <br />
            {sacop.CORONAVIRUS != undefined ? (
              <>{sacop.CORONAVIRUS == 1 ? <>Não</> : <>Sim</>}</>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={styles.rowInforItem1}>
          <div>
            <b>Natureza da Despesa:</b>
            <br />
            {sacop.NATUREZADESPESA}
          </div>
        </div>
        <div className={styles.rowInforItem1}>
          <div>
            <b>Origem do Recurso:</b>
            <br />
            {sacop.ORIGEMRECURSO}
          </div>
        </div>
      </section>

      <div className="section-title-portarias">
        <h4>Documentos da Licitação</h4>
      </div>
      {removeLoading && (
        <TableFiles
          infor={sacop}
          files={file}
          cnpj={cnpj}
          name={"Sacop-Detalhes"}
        />
      )}
    </>
  );
}
