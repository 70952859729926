
import IconeAcessibilidade from "./AcessibilidadeIcone";
import IconeLibras from "./LibrasIcone";
import IconeRadar from './RadarIcone';


export default function JoinButtonsTopNavbar(){


  return (
    <>
     <IconeRadar/>
     <IconeAcessibilidade/>
     <IconeLibras/>
    </>
  );
}
