import {link, tokenLink, dnsPortal} from '../../services/variables'



//alguns link possuem token diferente do padrão 
const linksNavbar =
  [
    // Município
    {
      name:`Município`,
      top_list: true,
      img: 'Municipio',
      sub_link: [ 
        {
          name:`A Cidade`,
          link:`/municipio/cidade`
        },
        {
          name:`Distâncias`,
          link:`/municipio/distancias`
        },
        {
          name:`História`,
          link:`/municipio/historia`
        },
        {
          name:`População`,
          link:`/municipio/populacao`
        },
        {
          name:`Mapa`,
          link:`/municipio/mapa`
        },
        // {
        //   name:`Símbolos`,
        //   link:`/municipio/simbolos`
        // },
        {
          name:`Endereços Úteis`,
          link:`/municipio/enderecos-uteis`
        },
        // {
        //   name:`Distritos`,
        //   link:`/municipio/distritos`
        // },
        // {
        //   name:`Eventos`,
        //   link:`/municipio/eventos`
        // },
        {
          name:`Obras`,
          link:`${link}/portaltm/licitacao/obrapublica.xhtml?token=${tokenLink}`
        },
        // {
        //   name:`Veículos`,
        //   link:`/municipio/veiculos`
        // },
      ]
    },
    // Informações Institucionais
    {
      name:`Informações Institucionais`,
      top_list: true,
      img: 'Institucional',
      sub_link: [
        {
          name:`Registro das Competências`,
          link: `/informacoes-institucionais/registro-das-competencias`,
        },
        {
          name:`Mural`,
          link: `/informacoes-institucionais/mural`,
        },
        {
          name:`Estrutura Organizacional`,
          // link:`${link}/portal/organograma/organograma.xhtml?token=${tokenLink}`
          link: `/estrutura-organizacional`
        },
        {
          name:`Leis Municipais / Estaduais`,
          sub_link:[
            {
              name:`Lei Orgânica`,
              link:`/informacoes-institucionais/leis-municipais/estaduais/lei-organica`
            },
            {
              name:`Código Tributário`,
              link:`/informacoes-institucionais/leis-municipais/estaduais/codigo-tributario`
            },
            {
              name:`Leis Municipais`,
              link:`/informacoes-institucionais/leis-municipais/estaduais/lei`
            },
            {
              name:`Leis Estaduais`,
              link:`${link}/portal/transparencia/transparencia.xhtml?token=${tokenLink}`
            },
            {
              name:`Projeto de Leis`,
              link:`/informacoes-institucionais/leis-municipais/estaduais/projeto-de-lei`
            },
            {
              name:`Decretos `,
              link:`/informacoes-institucionais/leis-municipais/estaduais/decreto`
            },
            {
              name:`Portarias`,
              sub_link:[
                {
                  name:`Nomeação`,
                  link:`/informacoes-institucionais/leis-municipais/estaduais/portarias/nomeacao`
                },
                {
                  name:`Exoneração`,
                  link:`/informacoes-institucionais/leis-municipais/estaduais/portarias/exoneracao`
                },
                {
                  name:`Outras`,
                  link:`/informacoes-institucionais/leis-municipais/estaduais/portarias/outros`
                },
              ]
            },
            {
              name:`Outros Atos Administrativos `,
              link:`/informacoes-institucionais/leis-municipais/estaduais/outros-atos-administrativos`
            },
          ]
        },
        {
          name:`Perguntas e Respostas`,
          link:`${link}/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`
        },
        {
          name:`Fale Conosco`,
          link:`informacoes-institucionais/contato-2`
        },
      ]
    },
    // Diário Oficial
    {
      name:`Diário Oficial`,
      top_list: true,
      img: 'Diario_OFI',
      sub_link:[
        {
          name:`Diário Oficial Eletrônico`,
          link:`${link}/diario/diariov2.xhtml?token=${tokenLink}`
          // link:`/diario-oficial/diario-oficial-eletronico`
        },
        {
          name:`Lei de criação do Diário`,
          link:`/diario-oficial/lei-de-criacao-do-diario`
        },
        {
          name:`Ato de nomeação do responsável`,
          link:`/diario-oficial/ato-de-nomeacao-do-responsavel`
        },
      ]
    },
    // Pessoal
    {
      name:`Pessoal`,
      top_list: true,
      img: 'Pessoal',
      sub_link:[
        {
          name:`Contracheque`,
          link:`http://contracheque.maclocacao.com:9906/sipweb/trabalhador/login/login.xhtml`
        },
        {
          name:`Estatuto do Servidor`,
          link:`/pessoal/estatuto-dos-servidores`
        },
        {
          name:`Folha de pagamento`,
          sub_link:[
            {
              name:`Gestão Atual`,
              link:`http://www.inforfolha.com.br/inforfolha/contracheque/remuneracao.xhtml?token=6b475e3c-c541-4473-8c5f-081e0a49be2c` //link diferente 
            },
            {
              name:`Gestão Anterior`,
              link:`${link}/portaltm/contracheque/remuneracao.xhtml?token=${tokenLink}`
            },
          ]
        },
        {
          name:`Servidores inativos`,
          link:`/pessoal/relacao-de-servidores-inativos`
        },
        {
          name:`Servidores ocupante de cargo em comissão`,
          link:`/pessoal/relacao-de-servidores-ocupantes-de-cargo-em-comissao`
        },
        {
          name:`Servidores cedidos para outros Órgãos Públicos`,
          link:`/pessoal/relacao-de-servidores-cedidos-para-outros-orgaos-publicos`
        },
        {
          name:`Relação nominal dos estagiários`,
          link:`/pessoal/relacao-nominal-dos-estagiarios`
        },
        {
          name:`Relação de contratados temporariamente e contratos de terceirização`,
          link:`/pessoal/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao`
        },
        {
          name:`Tabela com o padrão remuneratório dos cargos e função`,
          link:`/pessoal/tabela-remuneratoria-dos-cargos-e-funcoes`
        },

      ]
    },
    // Diárias
    {
      name:`Diárias`,
      top_list: true,
      img: 'Diárias',
      sub_link:[
        {
          name:`Tabela de valores das diárias`,
          link:`/diarias/tabela-de-valores-das-diarias`
        },
        {
          name:`Diárias e Passagens`,
          link:`http://portalcontreina.dcfiorilli.com.br:8022/link/Default.aspx?AcessoIndividual=lnkDiarias`
        },
        {
          name:`Legislação`,
          link:`/diarias/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais`
        },
      ]
    },
    // Transparência
    {
      name:`Transparência`,
      link:`/transparencia`,
      top_list: true,
      img: 'Transparência',

    },
    // Licitações e Contratos
    {
      name:`Licitações e Contratos`,
      top_list: true,
      img: 'licitaçoes',
      sub_link:[
        {
          name:`Indicação do Fiscal de Contrato`,
          link:`/licitacoes-e-contratos/indicacao-do-fiscal-do-contrato`
        },
        {
          name:`Avisos`,
          link:`/licitacoes-e-contratos/avisos`
        },
        {
          name:`Edital`,
          link:`/licitacoes-e-contratos/edital`
        },
        {
          name:`Dispensas`,
          link:`/licitacoes-e-contratos/dispensas`
        },
        {
          name:`Inexigibilidade`,
          link:`/licitacoes-e-contratos/inexigibilidade`
        },
        {
          name:`Pregão Eletrônico`,
          link:`/licitacoes-e-contratos/pregao-eletronico`
        },
        {
          name:`Ata de Adesão - SRP `,
          link:`/licitacoes-e-contratos/ata-de-adesao`
        },
        {
          name:`Resultados do Certame`,
          link:`/licitacoes-e-contratos/resultado-do-certame`
        },
        {
          name:`Desertas e Fracassadas`,
          link:`/licitacoes-e-contratos/desertas-fracassadas`
        },
        {
          name:`Termo de Aditivo`,
          link: `licitacoes-e-contratos/termo-de-aditivo`
        },
        {
          name:`Convênio`,
          link:`/licitacoes-e-contratos/convenio`
        },
        {
          name:`Contratos `,
          // link:`${link}/portaltm/contrato/contrato.xhtml?token=${tokenLink}`
          link: `/licitacoes-e-contratos/contrato`
          //licitacoes-e-contratos/contrato
        },
      ]
    },
    // Ouvidoria
    {
      name:`Ouvidoria`,
      top_list: true,
      img: 'Ouvidoria',
      sub_link: [
        {
          name:`Canal Eletrônico`,
          // link:`${link}/ouvidoriatm/index.xhtml?token=${tokenLink}`,
          link: `/ouvidoria/canal-eletronico`
        },
        {
          name:`Detalhe`,
          link: `/ouvidoria/detalhe`
        },
        // {
        //   name:`Atendimento Presencial`,
        //   link:`#`,
        // },
        // {
        //   name:`Carta de Serviços ao Usuário`,
        //   link:`#`,
        // },
      ]
    },
    // Cidadão | e-SIC
    {
      name:`Cidadão | e-SIC `,
      top_list: true,
      img: 'Ouvidoria',
      sub_link:[
        {
          name:`e-SIC - Regulamentação`,
          link:`${link}/portal/sic/sic.xhtml?token=${tokenLink}`
        },
        {
          name:`e-SIC - Perguntas e Respostas`,
          link:`${link}/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`
        },
        {
          name:`e-SIC`,
          link:`${link}/esic/index.xhtml?token=${tokenLink}`
        },
        {
          name:`Instrumento normativo local que regulamente a LAI`,
          link:`${link}/portal/transparencia/transparencia.xhtml?token=${tokenLink}`
        },
        {
          name:`e-SIC - Rol de Informações (12 meses)`,
          link:`/cidadao-e-sic/e-sic-rol-de-informacoes(12meses)`
        },
        {
          name:`e-SIC - Rol de documentos (Grau Sigilo)`,
          link:`/cidadao-e-sic/e-sic-rol-de-documentos(12meses)`
        },
        
      ]
    },
    // Tributos
    {
      name:`Tributos`,
      top_list: true,
      img: 'Contribuinte',
      sub_link:[
        {
          name:`Nota Fiscal Eletrônica`,
          link:`http://tributario.aspec.com.br/portal.ma.raposa/login.xhtml`
        },
        {
          name:`2ª via do IPTU`,
          link:`http://tributario.aspec.com.br/portal.ma.raposa/UC0085IPTU/T0085L-display-iptu.xhtml`
        },
        {
          name:`Certidões`,
          link:`http://tributario.aspec.com.br/portal.ma.raposa/UC0084EmitirCertidao/T0084L-emitir-certidao.xhtml`
        },
      ]
    },
    // Usuário
    {
      name:`Usuário`,
      top_list: true,
      img: 'admin-navbar',
      sub_link:[
        {
          name:`Login - Portal`,
          link:`/admin/login`
        },
        {
          name:`Login - Diário`,
          link:`${link}/diario/login.xhtml`
        },
        {
          name:`Login - Licitação`,
          link:`${link}/licitacaotm/login.xhtml`
        },
        {
          name:`Login - e-SIC`,
          link:`${link}/esic/adm/login.xhtml?token=${tokenLink}`
        },
        {
          name:`WebMail`,
          link:`https://webmail-seguro.com.br/${dnsPortal}/`
        },
      ]
    }
  ]
  export default linksNavbar