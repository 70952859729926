import "../../../styles/lists.css";
import { Button } from "react-bootstrap";
import Loading from "../../public/others/LoadingFull";
import { useListNews } from "./hooks/useListNews";

export default function List() {
  const {
    mutate,
    data,
    progress,
    listLoading,
    mutationLoading,
    listError,
    mutationError,
  } = useListNews();

  return (
    <div className="container-noticias">
      {listLoading && <Loading />}
      {mutationLoading && (
        <Loading progress={progress > 0 && progress != 100 && progress} />
      )}
      <span>🚨Sistema de noticias passando por melhorias. </span>
      <div className="sub-header-news">
        <h2>Painel de Notícias</h2>
        <Button
          className="btn-success"
          variant="primary"
          href={"/admin/noticias/criar"}
        >
          Criar nova notícia
        </Button>
      </div>
      <div className="container-lista">
        {data?.map((news) => (
          <ul
            key={news.SLUG}
            className={`noticia-item ${
              news.DISPONIVEL_VISUALIZACAO == 1 ? "V" : "O"
            }`}
          >
            <li className="title-td">{news.TITULO}</li>
            <li>Data: {converterData(news.DATA_POST)}</li>
            <li>Visualizações: {news.VISUALIZACAO}</li>
            <li className="views-td">
              {news.DISPONIVEL_VISUALIZACAO == 1 ? "Visivel" : "Oculta"}
            </li>
            <li className="container-botoes">
              <Button
                className="btn-Danger"
                variant="primary"
                href={"/noticia/" + news.SLUG}
              >
                Visualizar
              </Button>
              <Button
                className="btn-Danger"
                variant="warning"
                href={"/admin/noticias/editar/" + news.SLUG}
              >
                Editar
              </Button>
              <Button
                className="btn-Danger"
                variant="danger"
                onClick={() => mutate(news)}
              >
                Apagar
              </Button>
            </li>
          </ul>
        ))}
      </div>
      {!mutationError && !listError && !data?.length && (
        <p>Nenhuma noticia cadastrada</p>
      )}
      {listError && (
        <span className="error-text">
          Erro ao carregar noticias, por favor recarregue a página. Se o erro
          persistir, entre em contato com o suporte.
        </span>
      )}
      {mutationError && (
        <span className="error-text">
          Erro ao deletar noticia, por favor recarregue a página. Se o erro
          persistir, entre em contato com o suporte.
        </span>
      )}
    </div>
  );
}

function converterData(dataString) {
  const data = new Date(dataString);
  data.setMinutes(data.getTimezoneOffset());
  return data.toLocaleDateString("pt-BR");
}
