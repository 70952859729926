import styles from "../../../../styles/module/transparency/sacop.module.css";

export function CardAvisos({ data }) {
  return (
    <div className={styles.card_aviso}>
      <a
        href={`/licitacoes-e-contratos/detalhes/${data.ID}#docs`}
        target="_self"
        rel="noopener noreferrer"
        title="Ver detalhes do aviso de licitação"
        aria-label="Ver detalhes do aviso de licitação"
        role="link"
        tabIndex={0}
        className={styles.card_link}
      >
        <div>
          <p>
            <picture>
              <img src="/icons/mdi_database-cog.svg" alt="mdi_database" />
            </picture>{" "}
            <b>Número do Processo:</b> {data.NUMERO_PROCESSO}
          </p>
          <p>
            <picture>
              <img src="/icons/mdi_database-cog.svg" alt="mdi_database" />
            </picture>{" "}
            <b>Número do Instrumento:</b> {data.NUMERO_INSTRUMENTO}
          </p>
        </div>
        <div>
          <p>
            <picture>
              <img
                src="/icons/icon-park-outline_info.svg"
                alt="icon-park-outline_info"
              />
            </picture>{" "}
            <b>Modalidade:</b> {data.MODALIDADE_NOME}
          </p>
          <p>
            <picture>
              <img src="/icons/clarity_date-line.svg" alt="data_icon" />
            </picture>{" "}
            <b>Ano de Exercício:</b> {data.EXERCICIO}
          </p>
        </div>

        <p className={styles.card_objeto}>{data.OBJETO}</p>

        {/* <picture className={styles.card_icon}>
          <source srcSet="/icons/licitaçoes.svg" type="image/svg+xml" />
          <img
            width={30}
            height={30}
            src="/icons/licitaçoes.svg"
            alt="Ícone de licitação"
            aria-hidden="true"
          />
        </picture> */}
      </a>
    </div>
  );
}
