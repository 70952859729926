import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Loading from "../../Loading";
import styled from "../../../../../styles/module/seachDatabase.module.css";

export default function SearchNews(prop) {
  const wordSearch = prop.wordSearch;

  const [removeLoading, setRemoveLoading] = useState(true); //loading
  const [showResult, setShowResult] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [countResult, setCountResult] = useState(0);

  useEffect(() => {
    if (countResult == 0) {
      setRemoveLoading(false);

      api
        .get(`/search/news/${wordSearch}`)
        .then((res) => {
          if (res.data.err) {
            //erro
            setRemoveLoading(true);
          } else {
            setRemoveLoading(true);
            setShowResult(res.data.res);
          }
          setCountResult(1);
        })
        .catch((err) => {
          //não teve responta da api
          setCountResult(1);
        });
    }
  }, [showBox]);

  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "Janeiro",
      1: "Fevereiro",
      2: "Março",
      3: "Abril",
      4: "Maio",
      5: "Junho",
      6: "Julho",
      7: "Agosto",
      8: "Setembro",
      9: "Outubro",
      10: "Novembro",
      11: "Dezembro",
    };
    const date = new Date(dateReq);
    date.setDate(date.getDate() + 1);

    var day = date.getDate();
    if (date.getDate() < 10) {
      var day = "0" + date.getDate();
    }
    const formatDate =
      day + " de " + nameMonth[date.getMonth()] + " de " + date.getFullYear();
    return formatDate;
  };

  return (
    <>
      {!showBox ? (
        <section
          className={styled.boxDataBase}
          onMouseEnter={() => setShowBox(true)}
          onClick={() => setShowBox(true)}
        >
          <span>nOTíCIAS</span>
          <img
            className={styled.imgDatabase}
            src={"/icons/array_slider_topoR.svg"}
          ></img>
        </section>
      ) : (
        <section
          className={styled.boxDataBase}
          onClick={() => setShowBox(false)}
        >
          <span>NOTÍCIAS</span>
          <img
            className={styled.imgDatabaseOpen}
            src={"/icons/array_slider_topoR.svg"}
          ></img>
        </section>
      )}

      {!showBox ? (
        <></>
      ) : (
        <>
          {!removeLoading ? (
            <Loading />
          ) : (
            <div className={styled.cardGrup}>
              {showResult.length == 0 ? (
                <div className={styled.noneResult}>
                  Nenhuma resultado encontrado
                </div>
              ) : (
                <>
                  {showResult.map((x, id) => (
                    <div className={styled.item} key={id}>
                      <div className={styled.dateCard}>
                        <span className={styled.dateText}>
                          {dateRender(x.DATA)}
                        </span>
                      </div>
                      <div className={styled.titleCard}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"/noticia/" + x.ID}
                          className={styled.titleText}
                        >
                          TITULO: {x.TITULO}
                          <br />
                          CATEGORIA: {x.CATEGORIA}
                        </a>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}{" "}
        </>
      )}
    </>
  );
}
