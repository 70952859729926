import { TipoPortal } from "../../../services/variables";
import styled from "../../../styles/module/others/mapSite.module.css";
import jsonMenu from "../../jsons/jsonNavbar";
import jsonMenuCM from "../../jsons/jsonNavbarCM";
import SubTitle from "../others/title/SubTitle";
 
  
export default function MapSite() {



  const arrayMap = jsonMenu.map((itens,indexArrayMap//vai percorrer o array e vai retornar os elementos da lista
    ) => sumList(itens, indexArrayMap)
  );
  const arrayMapCM = jsonMenuCM.map((itens,indexArrayMap//vai percorrer o array e vai retornar os elementos da lista
  ) => sumList(itens, indexArrayMap)
);


  function sumList(array, index) {
    //vai aplicar o jsx

    if (array.sub_link !== undefined) {
      /*vai verificar se existe uma lista dentro de outra lista
    <ul>
    <li></li>
    <li></li>
    <li><ul></ul></li>
    </ul>
      */

      return (
        <>
          <li className={styled.listItemPrimary} key={index}>
            {checkLink(array)}
            <ul className={styled.subList}>
              {array.sub_link.map((itens, index) => sumList(itens, index))}
            </ul>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className={styled.subItem} key={array.name}>
            <a href={array.link} className={styled.link}>
              {array.name}
            </a>
          </li>
        </>
      );
    }
  }
  //vai verificar se existe um link (o elemento pai de uma lista oculta não deve possuir link)
  function checkLink(array) {
    if (array.link !== undefined) {
      return (
        <a href={array.link} className="drop-navba" key={array.name}>
          <p>{array.name}</p>
        </a>
      );
    } else {
      return <p className={styled.textTopList}>{array.name}</p>;
    }
  }

  
  const list_sub_link = (array) => {
    return (
      <div className="sub-div-sidebar-ne">
        {array.map((b, index) => (
          <li key={index} className="des">
            {b.link !== undefined ? (
              <a href={b.link} className="desq link-sideba">
                {b.name}
              </a>
            ) : (
              <h6 className="des">{b.name}</h6>
            )}
            <ul>{b.sub_link !== undefined ? list_sub_link(b.sub_link) : ""}</ul>
          </li>
        ))}
      </div>
    );
  };
 

  


  return (
    <>
      <div className="section-title rotes_pages">
      <h6>
      Início / Mapa Do Site
      </h6>
      </div>
       <SubTitle subTitle={'Mapa do Site'} border={true}/>
      <section className="section-title">
      
 
          
           {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
             {TipoPortal == 3 ? 
              <ul className="map-site">{arrayMap}</ul>
              : 
              <ul className="map-site">{arrayMapCM}</ul>
              }
       
      </section>


    </>
  );
}
