import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {NomeTipoPortal,NomePortal} from '../../../../../../../services/variables'
import {logoPortalBase64} from '../../../../../../../services/logoBase64'

export default  function PDFgenerate(props) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
    const data = props
    const arrayData2 = [[
        {text:  'EXERCICIO',bold: true, fontSize: 9},
             {text: 'NUMERO PROCESSO',bold: true, fontSize: 9},
             {text: 'NUMERO INSTRUMENTO',bold: true, fontSize: 9},
             {text: 'DATA ABERTURA',bold: true, fontSize: 9},
             {text: 'DATA PUBLICACAO',bold: true, fontSize: 9},
             {text: 'UNIDADE',bold: true, fontSize: 9},
             {text: 'MODALIDADE ',bold: true, fontSize: 9},
             {text: 'TIPO LICITACAO ',bold: true, fontSize: 9},
             {text: 'TIPO OBJETO',bold: true, fontSize: 9},
             {text: 'STATUS LICITACAO',bold: true, fontSize: 9},
             {text: 'VALOR TOTAL DESPESA',bold: true, fontSize: 9},
             {text: 'OBJETO',bold: true, fontSize: 9},
             {text: 'DATA ENVIO',bold: true, fontSize: 9}]
    ]
  
    data.map((d, i) => {
        arrayData2.push([
            {text:  d.EXERCICIO,fontSize: 8},
             {text: d.NUMERO_PROCESSO,fontSize: 8},
             {text: d.NUMERO_INSTRUMENTO,fontSize: 8},
             {text: d.DATA_ABERTURA,fontSize: 8},
             {text: d.DATA_PUBLICACAO,fontSize: 8},
             {text: d.UNIDADE_NOME,fontSize: 8},
             {text: d.MODALIDADE_NOME,fontSize: 8},
             {text: d.TIPO_LICITACAO_NOME,fontSize: 8},
             {text: d.TIPO_OBJETO_NOME,fontSize: 8},
             {text: d.STATUS_LICITACAO_NOME,fontSize: 8},
             {text: d.VALOR_TOTAL_DESPESA,fontSize: 8},
             {text: d.OBJETO,fontSize: 8},
             {text: d.DATA_ENVIO,fontSize: 8},
            ]) 
    })
 
    const reportTitle = [
        {

        }
    ]
    const details = [
        {image: logoPortalBase64,
	    width: 50,height: 50,alignment: 'center'},	
        {text: `${NomeTipoPortal} de ${NomePortal}\n\n`,fontSize: 14,alignment: 'center'},
        {
			style: 'tableExample',
			table: {
				headerRows: 1,
				// dontBreakRows: true,
				// keepWithHeaderRows: 1,
				body: arrayData2
			}
		},
    ]
    // const footer = [{text: `Arquivo gerado no portal: ${new Date().toLocaleString()} \n`,fontSize: 8, alignment: 'center'}]
    const footer = [{text: ``}]

    
    const docDefinitions = {
        pageSize: 'A2',
        pageMargins:[15,50,15,40],
        headers : [reportTitle],
        content: [details],
        footer: [footer],
        
    }
 pdfMake.createPdf(docDefinitions).open()//.print()//.download()
    
}