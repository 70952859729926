import styled from '../../../../styles/module/transparency/ouvidoria/form.module.css';
import Footer from '../../others/Footer';
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';


export default function FromSubmitOuvidoria() {
  
  
  return (
    <>
   
   
   <Alert  variant={'danger'}>
          Sistema em Manutenção !
        </Alert>
    <form action="http://" method="post" className={styled.gridFromOuvidoria1}>
        <div className={styled.columnForm}>
            <h6>Dados Pessoais</h6>
            <input  type="name" name='Name' placeholder="Nome"/>
            <input type="email" name='Email' placeholder="Email"/>
            <input type="tel" name='Celular' placeholder='Celular'/>
        </div>
        
        <div className={styled.columnForm}>
            <h6>Endereço</h6>
            <input type="name" name='Endereco' placeholder="Endereço"/>
            <input type="name" name='Bairro' placeholder='Bairro'/>
            <div className={styled.selectUf}>  
            <input type="name" name='Cidade' placeholder="Cidade"/>
            <select id="UF" name="UF">
                <option value="">Estado</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espirito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MT">Mato Grosso</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
            </select>
           </div>
           </div>
        
        <div className={styled.columnFormDiv2}>
            <h6>Mensagem para a ouvidoria</h6>
        <select name='Secretária'>
            <option value="">Secretária</option>
         
        </select>
        <select name='Tipo Manifestação'>
            <option>Tipo Manifestação</option>
          
        </select>
        </div>  
        <div className={styled.columnFormDiv2BigSize}>
            <h6></h6>
            <input type="text" maxlength="500" name='Assunto' placeholder="Assunto"/>
        </div>
        <div className={styled.ouvidoriButton}>
            <button hidden="none"></button>
        </div>
        <div className={styled.ouvidoriButton}>
            <Button value={'Enviar'}>Enviar</Button>
        </div>
    </form>
    <section className={styled.footer}>

    <Footer/>
    </section>
    
        
  
    </>
  )
}