import AdminNavbar from "../../../components/admin/NavbarLogo";
import Login from "../../../components/admin/user/Login";

export default function AdminNewsPage() {
  return (
    <>
      <AdminNavbar />
      <div>
        <Login />
      </div>
    </>
  );
}
