import "../../../../../../styles/filter_sacop.css";

export default function FiltrosSacop(props) {
  const {
    portaria,
    setModalidade,
    setStatus,
    setUnidade,
    setDespesa,
    setInstrumento,
    setProcesso,
  } = props;

  if (portaria.length == 0) {
    return null;
  }

  return (
    <div className="container_filters">
      {Array.from(new Set(portaria.map((item) => item.MODALIDADE_NOME))).map(
        (modalidade) => {
          return portaria.find((item) => item.MODALIDADE_NOME === modalidade);
        }
      ).length == 1 ? null : (
        <label className="label_filter" htmlFor="SELECT_MODALIDADE">
          <b>Filtrar Modalidade</b>
          <select
            name="SELECT_MODALIDADE"
            id="SELECT_MODALIDADE"
            onChange={(e) => {
              setModalidade(e.target.value);
            }}
          >
            <option value="">Todas</option>
            <option value="PREGÃO PRESENCIAL">PREGÃO PRESENCIAL</option>
            <option value="TOMADA DE PREÇOS">TOMADA DE PREÇOS</option>
            <option value="RDC PRESENCIAL">RDC PRESENCIAL</option>
            <option value="CONVITE">CONVITE</option>
            <option value="LEILÃO">LEILÃO</option>
            <option value="CONCURSO">CONCURSO</option>
            <option value="CHAMADA PÚBLICA">CHAMADA PÚBLICA</option>
            <option value="DISPENSA">DISPENSA</option>
            <option value="ADESÃO A ATA DE REGISTRO DE PREÇO">
              ADESÃO A ATA DE REGISTRO DE PREÇO
            </option>
            <option value="CREDENCIAMENTO">CREDENCIAMENTO</option>
            <option value="DIÁLOGO COMPETITIVO">DIÁLOGO COMPETITIVO</option>
            <option value="INEXIGIBILIDADE">INEXIGIBILIDADE</option>
            <option value="CONCORRÊNCIA ELETRÔNICA">
              CONCORRÊNCIA ELETRÔNICA
            </option>
            <option value="DISPENSA ELETRÔNICA">DISPENSA ELETRÔNICA</option>
            <option value="CARTA CONVITE">CARTA CONVITE</option>
            <option value="CONCORRÊNCIA PÚBLICA">CONCORRÊNCIA PÚBLICA</option>
            <option value="RDC ELETRÔNICO">RDC ELETRÔNICO</option>
            <option value="LICITAÇÃO INTERNACIONAL">
              LICITAÇÃO INTERNACIONAL
            </option>
            <option value="CONCORRÊNCIA">CONCORRÊNCIA</option>
            <option value="PROCEDIMENTOS AUXILIARES">
              PROCEDIMENTOS AUXILIARES
            </option>
            <option value="PREGÃO ELETRÔNICO">PREGÃO ELETRÔNICO</option>
          </select>
        </label>
      )}

      <label className="label_filter" htmlFor="SELECT_STATUS">
        <b>Filtrar Status</b>
        <select
          name="SELECT_STATUS"
          id="SELECT_STATUS"
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <option value="">Todas</option>
          <option value="SUSPENSO">SUSPENSO</option>
          <option value="CANCELADO">CANCELADO</option>
          <option value="PENDENTE DE ENVIO AO TCE">
            PENDENTE DE ENVIO AO TCE
          </option>
          <option value="FRACASSADA">FRACASSADA</option>
          <option value="FINALIZADA">FINALIZADA</option>
          <option value="ENVIADO AO TCE">ENVIADO AO TCE</option>
          <option value="REVOGADA">REVOGADA</option>
          <option value="FINALIZADO">FINALIZADO</option>
          <option value="EM ANDAMENTO">EM ANDAMENTOA</option>
          <option value="HOMOLOGADA/RATIFICADA">HOMOLOGADA/RATIFICADA</option>
          <option value="EM AVISO">EM AVISO</option>
          <option value="DESERTA">DESERTA</option>
          <option value="ANULADA">ANULADA</option>
          <option value="DESATIVADO(TCE)">DESATIVADO(TCE)</option>
          <option value="EM INTENCAO DE LICITAR">EM INTENCAO DE LICITAR</option>
        </select>
      </label>
      <label className="label_filter" htmlFor="SELECT_UNIDADE">
        <b>Filtrar Unidade</b>
        <select
          name="SELECT_UNIDADE"
          id="SELECT_UNIDADE"
          onChange={(e) => {
            setUnidade(e.target.value);
          }}
        >
          <option value="">Todas</option>
          {Array.from(new Set(portaria.map((item) => item.UNIDADE_NOME)))
            .map((unidade) => {
              return portaria.find((item) => item.UNIDADE_NOME === unidade);
            })
            .map((item, index) => (
              <option key={index} value={item.UNIDADE_NOME}>
                {item.UNIDADE_NOME}
              </option>
            ))}
        </select>
      </label>
      <label className="label_filter" htmlFor="PROCESSO">
        <b>Número do Processo:</b>
        <input
          type="search"
          name="PROCESSO"
          id="PROCESSO"
          onChange={(e) => {
            setProcesso(e.target.value);
          }}
        />
      </label>
      <label className="label_filter" htmlFor="INPUT_INSTRUMENTO">
        <b>Número do Instrumento:</b>
        <input
          type="search"
          name="INPUT_INSTRUMENTO"
          id="INPUT_INSTRUMENTO"
          onChange={(e) => {
            setInstrumento(e.target.value);
          }}
        />
      </label>
      <label className="label_filter" htmlFor="INPUT_PESQUISA">
        <b>Valor da despesa:</b>
        <input
          type="search"
          name="INPUT_PESQUISA"
          id="INPUT_PESQUISA"
          onChange={(e) => {
            setDespesa(e.target.value);
          }}
        />
      </label>
    </div>
  );
}
