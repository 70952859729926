import AdminNavbar from "../../../components/admin/Navbar";

import FormNew from "../../../components/admin/office/New";

export default function AdminNewBanner() {
  return (
    <>
      <AdminNavbar />
      <div>
        <FormNew />
      </div>
    </>
  );
}
