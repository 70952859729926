import styled from "../../../../../../styles/module/transparency/sacopLicitacaoList.module.css";
import styles from "../../../../../../styles/module/transparency/cardSacop.module.css";

export default function cardSacop(props) {
  const data = props.data;
  const status = [
    {
      color: styles.greenStatus,
      status: [
        { nome: "VIGENTE" },
        { nome: "ENVIADO AO TCE" },
        { nome: "FINALIZADO" },
        { nome: "FINALIZADA" },
        { nome: "HOMOLOGADA/RATIFICADA" },
        { nome: "" },
      ],
    },
    {
      color: styles.yelowStatus,
      status: [
        { nome: "PENDENTE DE ENVIO AO TCE" },
        { nome: "EM ANDAMENTO" },
        { nome: "EM AVISO" },
        { nome: "SEM STATUS" },
        { nome: "EM INTENCAO DE LICITAR" },
      ],
    },
    {
      color: styles.redStatus,
      status: [
        { nome: "REVOGADA" },
        { nome: "ANULADO" },
        { nome: "FRACASSADA" },
        { nome: "DESERTA" },
        { nome: "ANULADA" },
        { nome: "CANCELADO" },
        { nome: "DESATIVADO(TCE)" },
        { nome: "SUSPENSO" },
      ],
    },
  ];

  return (
    <div className={styled.card}>
      <div
        className={styles.box}
        style={{
          flex: "0.5",
        }}
      >
        <div className={styles.info_box}>
          {status.map((item) =>
            item.status.map((status) =>
              status.nome === data.STATUS_CONTRATO ? (
                <div
                  key={status.nome}
                  className={`${styles.status} ${item.color}`}
                >
                  <p>Nº Contrato</p>
                  <b>
                    {data.NUMERO_CONTRATO != "" ? data.NUMERO_CONTRATO : "N/A"}
                  </b>
                </div>
              ) : null
            )
          )}
          <div className={styles.sequencia}>
            <b>Sequência: </b>
            {data.SEQUENCIA}
          </div>
        </div>
      </div>
      <div
        className={styles.box}
        style={{
          flex: "2",
        }}
      >
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img src="/icons/clarity_date-line.svg" alt="data_icon" />
            </picture>{" "}
            <b>Exercício: </b>
            {data.EXERCICIO}
          </div>
          {data.DATA_VIGENCIA_INICIO !== "" ? (
            <div>
              <b>Data Assinatura: </b>
              {data.DATA_VIGENCIA_INICIO}
            </div>
          ) : (
            <div>
              <b>Data Assinatura: </b>
              N/A
            </div>
          )}
          {data.DATA_VIGENCIA_INICIO !== "" ? (
            <div>
              <b>Inicio Vigência: </b>
              {data.DATA_VIGENCIA_INICIO}
            </div>
          ) : (
            <div>
              <b>Inicio Vigência: </b>
              N/A
            </div>
          )}
          {data.DATA_VIGENCIA_FIM !== "" ? (
            <div>
              <b>Fim Vigência: </b>
              {data.DATA_VIGENCIA_FIM}
            </div>
          ) : (
            <div>
              <b>Fim Vigência: </b>
              N/A
            </div>
          )}
        </div>
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img src="/icons/mdi_database-cog.svg" alt="mdi_database" />
            </picture>{" "}
            <b>Fornecedor: </b>
            {data.NOME_FORNECEDOR !== "" ? data.NOME_FORNECEDOR : "N/A"}
          </div>{" "}
          {data.FORNECEDOR_CPF_CNPJ !== "" &&
          data.FORNECEDOR_CPF_CNPJ.length !== 14 ? (
            <div>
              <b>CPF/CNPJ: </b>
              {data.FORNECEDOR_CPF_CNPJ}
            </div>
          ) : (
            <div>
              <b>CPF/CNPJ: </b>-
            </div>
          )}
        </div>
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img
                src="/icons/icon-park-outline_info.svg"
                alt="icon-park-outline_info"
              />
            </picture>{" "}
            <b>Valor do Contrato: </b>
            {data.VALOR_CONTRATO !== "" ? data.VALOR_CONTRATO : "N/A"}
          </div>
          {data.STATUS_CONTRATO !== "" ? (
            <div>
              <b>Status contrato: </b>
              {data.STATUS_CONTRATO}
            </div>
          ) : (
            <div>
              <b>Status contrato: </b>
              N/A
            </div>
          )}
        </div>
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img src="/icons/bi_building-fill.svg" alt="bi_building-fill" />
            </picture>{" "}
            <b>Unidade: </b>
            {data.NOME_UNIDADE}
          </div>
        </div>
        <div className={styles.box}>
          <a
            href={`/licitacoes-e-contratos/contratos/detalhes/${data.ID_CONTRATO}`}
            target="_self"
            color="blue"
          >
            <b>Objeto: </b>
            {data.OBJETO_CONTRATO}
          </a>
        </div>
      </div>
      <div
        className={styles.box}
        style={{
          flex: "0.3",
        }}
      >
        <a
          href={`/licitacoes-e-contratos/contratos/detalhes/${data.ID_CONTRATO}`}
          target="_self"
          className={styles.btn}
        >
          DETALHAR
        </a>
        <div className={styles.info_box}>
          {data.STATUS_CONTRATO != "" &&
            status.map((item) =>
              item.status.map((status) =>
                status.nome === data.STATUS_CONTRATO ? (
                  <div
                    key={status.nome}
                    className={`${styles.status} ${item.color}`}
                  >
                    {status.nome}
                  </div>
                ) : null
              )
            )}
        </div>
      </div>
    </div>
  );
}
