import AdminNavbar from "../../../components/admin/NavbarLogo";
import CreatedUser from "../../../components/admin/user/CreatedUser";

export default function AdminNewsPage() {
  return (
    <>
      <AdminNavbar />
      <div>
        <CreatedUser />
      </div>
    </>
  );
}
