import NavMenu from '../../../../components/public/others/Navbar'
import NavMenuCM from '../../../../components/public/others/NavbarCM'
import Footer from '../../../../components/public/others/Footer'
import { TipoPortal } from "../../../../services/variables"
import SubTitle from '../../../../components/public/others/title/SubTitle'
import Title from '../../../../components/public/others/title/Title'
import LinkAndInfo from '../../../../components/public/transparency/esic/Buttons'
import EsicResult from '../../../../components/public/transparency/esic/viewsResult/List'
import BarChartEsic from '../../../../components/public/transparency/esic/Chart'
import { useEffect } from 'react'
import api from '../../../../services/api'
import { useState } from 'react'
import Loading from './../../../../components/public/others/Loading';


export default function ESic() {

  const [years, setYears] = useState([]) 
  const [summaryOfAllYears, setSummaryOfAllYears] = useState({}) 
  const [data, setData] = useState({}) 




useEffect(() =>{
    // console.log('11')
    // console.log(Object.keys(data).length)
  },[data])

  useEffect(()=>{

    api.get(`/esic/graphic/questions`).then((res)=>{
      if(res.data.err !== true){
        // console.log("Sucesso")

        const result = res.data
        setYears(result.subtypes)
        setData(result.res)
        setSummaryOfAllYears(result.summaryOfAllYears)

      }else{
        console.log("ERRO")
        setYears([])
        setData([])
      }
    }).catch((err)=>{}
    )},[])


  return (
    <>



       {TipoPortal == 3 ? <>
        <NavMenu />
      </> : <>
        <NavMenuCM />
      </>}
      <Title route={'Início / E-sic'} title={'E-SIC'} />      
      <SubTitle subTitle={'Perguntas e Respostas'} border={true} />
      <EsicResult data={data} name={'ESIC'}/>


      <SubTitle subTitle={'Gráficos'} border={true} />

      {Object.keys(data).length == 0 ?<Loading/>:<></>} 

      
     {Object.keys(data).length !== 0 ?
     <BarChartEsic data={data} years={years} summaryOfAllYears={summaryOfAllYears}/>
      : <>
      </>} 

      <Footer />
    </>)
}


