import api from '../../../../../services/api'
import { corIcones, NomePortal} from "../../../../../services/variables";
import { useEffect,useState} from "react";
import { Pagination, Table } from "react-bootstrap";
import Loading from "../../../others/LoadingFull";
import GeneratePDF from '../PDF'
import exportFromJSON from 'export-from-json'
import styledEsic from '../../../../../styles/module/transparency/e-sic/main.module.css'

import boxStyled from '../../../../../styles/module/transparency/e-sic/result/boxDetail.module.css'




  
  export default function BoxDetailEsic(props) {
    
    const data = props.data
    const dataDetail = data['data']['data']
    // console.log(dataDetail.length)
    return(
    <div className={boxStyled.boxDetail}>
        <div className={boxStyled.headerDetail}>
          <h6>Detalhes do pedido</h6>
            {/* <h6>Número Pedido: {dataDetail["NUMERO_PEDIDO"]}</h6> */}
            <svg  className={boxStyled.iconeDetail} 
            onClick={props.handle}
            viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
        </div>
        <div className={boxStyled.bodyDetail}>


{/* PEDIDO */}
          <div className={boxStyled.boxBodyColum}>
            <div><b>
              Número Pedido
              </b>
              <br/>
              {dataDetail['NUMERO_PEDIDO']}
              </div>

              <div><b>
              DATA ENVIO PEDIDO
              </b>
              <br/>
              {dataDetail['DATA_PEDIDO']}
              </div>

              <div><b>
              DATA ENCERRAMENTO PEDIDO
              </b>
              <br/>
              {dataDetail['DATA_ENCERRAMENTO'] == "" ? <>NÃO FINALIZADO</>: <>{dataDetail['DATA_ENCERRAMENTO']}</>} 
              {/* VERIFICAÇÃO NÃO ENCERRADO */}
              </div>

          
            </div>

            {/* RESUMO E DETALHES DO PEDIDO */}
            <div className={boxStyled.boxBodyColum1}>
            <div><b>
              RESUMO
              </b>
              <br/>
              {/* {dataDetail['RESPOSTA']} */}
              {dataDetail['RESUMO'] == "" ? <>NÃO APRESENTA RESUMO</>: <>{dataDetail['RESUMO'] }</>} 

              </div>
              </div>


              <div className={boxStyled.boxBodyColum1}>
            <div><b>
              DETALHES
              </b>
              <br/>
              {/* {dataDetail['RESPOSTA']} */}
              {dataDetail['DETALHE'] == "" ? <>NÃO APRESENTA DETALHES</>: <div dangerouslySetInnerHTML={{ __html: dataDetail['DETALHE'] }}></div>} 

              </div>
              </div>

           

            {/* RESPOSTA */}

            <div className={boxStyled.boxBodyColum1}>
            <div><b>
              RESPOSTA
              </b>
              <br/>
              {/* {dataDetail['RESPOSTA']} */}
              {dataDetail['RESPOSTA'] == "" ? <>NÃO RESPONDIDO</>: <div dangerouslySetInnerHTML={{ __html: dataDetail['RESPOSTA'] }}></div>} 

              </div>
              </div>
              
{/* RESPOSTA  DATAS*/}
            <div className={boxStyled.boxBodyColum}>
           <div><b>
              DATA DA RESPOSTA
              </b>
              <br/>
              {/* {dataDetail['DATA_RESPOSTA']} */}
              {dataDetail['DATA_RESPOSTA'] == "" ? <>NÃO RESPONDIDO</>: <>{dataDetail['DATA_RESPOSTA']}</>} 

              </div>

              <div><b>
              DATA PRAZO PARA RESPOSTA
              </b>
              <br/>
              {dataDetail['DATA_PRAZO']}
              </div>
              </div>

          
              {/* RECURSO */}
            <div className={boxStyled.boxBodyColum}>
            <div><b>
            RECURSO
              </b>
              <br/>
              {/* {dataDetail['RESPOSTA']} */}
              {dataDetail['RECURSO'] == "" ? <>NÃO APRESENTA RECURSO</>: <>{dataDetail['RECURSO'] }</>}

              </div>
              <div><b>
              DATA PRAZO PARA RECURSO
              </b>
              <br/>
              {/* {dataDetail['RESPOSTA']} */}
              {dataDetail['DATA_PRAZO_RECURSO'] == "" ? <>NÃO APRESENTA </>: <>{dataDetail['DATA_PRAZO_RECURSO'] }</>} 

              </div>
              </div>


            {/* <p>{dataDetail.length == undefined ?<>Nenhuma informação extra</> : <></>}</p> */}
        </div>
    </div>)
}