const jsonPortarias = [
  /*

    Legislativo Municipal

*/
  {
    TITLE: "Lei Orgânica",
    ROUTE: "Início / Legislativo Municipal / Legislação / Lei Orgânica",
    SELECT: "Lei Orgânica",
  },
  {
    TITLE: "Código Tributário",
    ROUTE: "Início / Legislativo Municipal / Legislação / Código Tributário",
    SELECT: "Código Tributário",
  },
  {
    TITLE: "Leis Municipais",
    ROUTE: "Início / Legislativo Municipal / Legislação / Leis Municipais ",
    SELECT: "Lei",
  },
  {
    TITLE: "Leis Ordinárias",
    ROUTE: "Início / Legislativo Municipal / Legislação / Leis Ordinária",
    SELECT: "Lei Ordinária",
  },
  {
    TITLE: "Projeto Executivo",
    ROUTE: "Início / Legislativo Municipal / Legislação / Projeto de lei ",
    SELECT: "Projeto",
  },
  {
    TITLE: "Decreto",
    ROUTE: "Início / Legislativo Municipal / Legislação / Decreto",
    SELECT: "Decreto",
  },
  /*

            Legislativo Municipal /  Legislação

        */
  {
    TITLE: "Portarias de Nomeações",
    ROUTE: "Início / Legislativo Municipal / Legislação / Portarias / Nomeação",
    SELECT: "Portaria Exoneração e Nomeação",
  },
  {
    TITLE: "Portarias de Exonerações",
    ROUTE:
      "Início / Legislativo Municipal / Legislação / Portarias / Exoneração",
    SELECT: "Portaria Exoneração",
  },
  {
    TITLE: "Portarias",
    ROUTE: "Início / Legislativo Municipal / Legislação / Portarias / Outros ",
    SELECT: "Portaria",
  },

  {
    TITLE: "Outros Atos Administrativos",
    ROUTE:
      "Início / Legislativo Municipal / Legislação / Outros Atos Administrativos",
    SELECT: "Outros Atos Administrativos",
  },

  /*

    Diário Oficial

*/
  {
    TITLE: "Lei de Criação do Diário Oficial Eletrônico do Município",
    ROUTE: "Início / Diário Oficial / Lei de Criação do Diário",
    SELECT: "Lei de Criação do Diário Oficial Eletônico do Municipio",
  },
  {
    TITLE:
      "Ato de Nomeação de Pessoa Responsável pelas Publicações do Diário Oficial do Município",
    ROUTE: "Início / Diário Oficial / Ato de nomeação do responsável",
    SELECT:
      "Ato de Nomeação de Pessoa Responsável pelas Publicações do Diário Oficial do Munícipio", //O texto é errado mesmo
  },

  /*

    Pessoal

*/

  {
    TITLE: "Estatuto dos Servidores",
    ROUTE: "Início / Pessoal",
    SELECT: "Estatuto dos Servidores",
  },
  {
    TITLE: "Relação de servidores inativos",
    ROUTE: "Início / Pessoal / Relação de servidores inativos",
    SELECT: "Relação de servidores inativos",
  },
  {
    TITLE: "Relação de servidores ocupantes de cargo em comissão",
    ROUTE:
      "Início / Pessoal / Relação de servidores ocupantes de cargo em comissão",
    SELECT: "Relação de servidores ocupantes de cargo em comissão",
  },
  {
    TITLE: "Relação de servidores cedidos para outros Órgãos Públicos",
    ROUTE:
      "Início / Pessoal / Relação de servidores cedidos para outros Órgãos Públicos",
    SELECT: "Relação de servidores cedidos para outros Órgãos Públicos",
  },
  {
    TITLE: "Relação nominal dos estagiários",
    ROUTE: "Início / Pessoal / Relação nominal dos estagiários",
    SELECT: "Relação nominal dos estagiários",
  },
  {
    TITLE:
      "Relação nominal dos agentes públicos contratados temporariamente e contratados de terceirização",
    ROUTE:
      "Início / Pessoal / Relação nominal dos agentes públicos contratados temporariamente e contratados de terceirização",
    SELECT:
      "Relação nominal dos agentes públicos contratados temporariamente e contratados de terceirização",
  },
  {
    TITLE: "Tabela remuneratória dos cargos e funções",
    ROUTE: "Início / Pessoal / Tabela remuneratória dos cargos e funções",
    SELECT: "Tabela remuneratória dos cargos e funções",
  },

  {
    TITLE: "Relação nominal de cada servidor",
    ROUTE: "Início / Pessoal / Relação nominal de cada servidor",
    SELECT: "Relação nominal de cada servidor",
  },

  /*

         Diárias

*/

  {
    TITLE: "Relação das diárias, dentro e fora Estado, e fora do País",
    ROUTE: "Início / Diárias / Tabela De Valores Das Diárias",
    SELECT: "Relação das diárias, dentro e fora Estado, e fora do País",
  },
  {
    TITLE: "Diárias e Legislação",
    ROUTE: "Início / Diárias / Legislação",
    SELECT: "Diárias e Legislação",
  },

  /*

         Licitações

*/

  {
    TITLE: "Indicação do Fiscal do Contrato",
    ROUTE: "Início / Licitações e Contratos / Indicação do Fiscal do Contrato",
    SELECT: "Indicação do Fiscal do Contrado", //O texto é errado mesmo
  },

  /*

        Cidadão | e-SIC

*/
  {
    TITLE:
      "Rol das informações que tenham sido desclassificadas nos últimos 12 (doze) meses",
    ROUTE: "Início / Cidadão | e-SIC / e-SIC - Rol de Informações (12 meses)",
    SELECT:
      "Rol das informações que tenham sido desclassificadas nos últimos 12 (doze) meses",
  },
  {
    TITLE:
      "Rol de documentos classificados em cada grau de sigilo, com identificação para referência futura",
    ROUTE: "Início / Cidadão|e-SIC / e-SIC - Rol de Documentos (12 meses)",
    SELECT:
      "Rol de documentos classificados em cada grau de sigilo, com identificação para referência futura",
  },

  {
    TITLE: "Regimento Interno",
    ROUTE: "Início / Legislativo Municipal / Legislação / Regimento Interno",
    SELECT: "Regimento Interno",
  },

  {
    TITLE: "Resoluções",
    ROUTE: "Início / Legislativo Municipal / Legislação / Resoluções",
    SELECT: "Resolução",
  },

  {
    TITLE: "Emendas",
    ROUTE: "Início / Legislativo Municipal / Legislação / Emendas",
    SELECT: "Emendas",
  },
  {
    TITLE: "Cotas para exercício da atividade parlamentar/verba",
    ROUTE:
      "Início / Legislativo Municipal / Cotas para exercício da atividade parlamentar/verba",
    SELECT: "Cotas para exercício da atividade parlamentar/verba indenizatória",
  },
  {
    TITLE: "Legislação relacionada a gastos dos parlamentares",
    ROUTE:
      "Início / Legislativo Municipal / Legislação relacionada a gastos dos parlamentares",
    SELECT: "Legislação relacionada a gastos dos parlamentares",
  },
  {
    TITLE: "Projeto Legislativo",
    ROUTE: "Início / Legislativo Municipal / Projeto Legislativo",
    SELECT: "Projeto Legislativo",
  },
  {
    TITLE: "Pauta das Comissões e das Sessões do Plenário",
    ROUTE:
      "Início / Legislativo Municipal / Pauta das Comissões e das Sessões do Plenário",
    SELECT: "Pauta das Comissões e das Sessões do Plenário",
  },
  {
    TITLE: "Solene",
    ROUTE: "Início / Legislativo Municipal / Solene",
    SELECT: "Solene",
  },
  {
    TITLE: "Ordinária",
    ROUTE: "Início / Legislativo Municipal / Ordinária",
    SELECT: "Ordinária",
  },
  {
    TITLE: "Extraordinária",
    ROUTE: "Início / Legislativo Municipal / Extraordinária",
    SELECT: "Extraordinária",
  },
  {
    TITLE: "Votações Nominais dos Projetos de Leis",
    ROUTE:
      "Início / Legislativo Municipal / Presenças e Ausências dos Parlamentares",
    SELECT: "Votações Nominais dos Projetos de Leis",
  },
  {
    TITLE: "Julgamento das Contas Anuais",
    ROUTE:
      "Início / Legislativo Municipal / Julgamento das Contas do Governador/Prefeito",
    SELECT: "Julgamento das Contas Anuais",
  },
  {
    TITLE: "Controle de Frequência dos Vereadores",
    ROUTE:
      "Início / Legislativo Municipal / Resultado Inspeções, prestações e tomadas de contas",
    SELECT: "Controle de Frequência dos Vereadores",
  },
  {
    TITLE:
      "Resultados de inspeções, auditorias, prestações e tomadas de contas",
    ROUTE:
      "Início / Legislativo Municipal / Resultado Inspeções, prestações e tomadas de contas",
    SELECT:
      "Resultados de inspeções, auditorias, prestaçãoes e tomadas de contas",
  },
  {
    TITLE: "Mural",
    ROUTE: "Início / Legislativo Municipal / Mural",
    SELECT: "Biênio",
  },
  {
    TITLE: "Em Instrumentos da Gestão Fiscal e de Planejamento",
    ROUTE:
      "Início / Transparência / Em Instrumentos da Gestão Fiscal e de Planejamento",
    SELECT: "EM INSTRUMENTOS DA GESTÃO FISCAL E DE PLANEJAMENTO",
  },
  {
    TITLE: "Conselhos Municipais",
    ROUTE:
      "Início / Transparência / Em Instrumentos da Gestão Fiscal e de Planejamento",
    SELECT: "EM INSTRUMENTOS DA GESTÃO FISCAL E DE PLANEJAMENTO",
  },

  {
    TITLE: "Programas, Ações, Projetos e Obras",
    ROUTE: "Início / Transparência / Programas, Ações, Projetos e Obras",
    SELECT: "Programas, Ações, Projetos e Obras",
  },
  {
    TITLE: "Parecer Prévio do TCE-MA",
    ROUTE: "Início / Transparência / Parecer Prévio do TCE-MA",
    SELECT: "Parecer Prévio do TCE",
  },
  {
    TITLE: "Parecer Prévio",
    ROUTE: "Início / Transparência / Parecer Prévio",
    SELECT: "Parecer Prévio do TCE",
  },
  {
    TITLE: "Audiências Públicas",
    ROUTE: "Início / Transparência / Audiências Públicas",
    SELECT: "AUDIENCIA",
  },
  {
    TITLE: "Gestão Fiscal",
    ROUTE: "Início / Transparência / Gestão Fiscal",
    SELECT: "GESTAO FISCAL",
  },
];

export default jsonPortarias;
