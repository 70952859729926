import "../../../styles/admin/news.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useCreateNews } from "./hooks/useCreateNews";
import { Editor } from "@tinymce/tinymce-react";
import Loading from "../../public/others/LoadingFull";

export default function CreatNews() {
  const {
    categoryData,
    categoryLoading,
    createNews,
    createNewsLoading,
    createNewsError,
    progress,
  } = useCreateNews();
  //Input type checked
  const [isChecked, setIsChecked] = useState(true);
  //campo das imagens
  const [imageCapa, setImageCapa] = useState(null);
  const [imagensSecundarias, setImagensSecundarias] = useState([]);
  //dados do Editor Tiny
  const [htmlData, setHtmlData] = useState("");
  const [pureText, setPureText] = useState("");
  //conteudos simples
  const [titulo, setTitulo] = useState("");
  const [data, setData] = useState("");
  const [categoria, setCategoria] = useState("");

  const handleEditorChange = (content, editor) => {
    setPureText(editor.getContent({ format: "text" }));
    setHtmlData(content);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newsData = new FormData();
    newsData.append("title", titulo ? titulo : "Noticia");
    newsData.append("bodyText", htmlData);
    newsData.append("textLessTag", pureText);
    newsData.append("img", imageCapa);
    newsData.append("date", data ? data : new Date());
    newsData.append("category", categoria ? categoria : "1");
    newsData.append("visible", isChecked ? 1 : 0);
    imagensSecundarias?.forEach((file) => {
      newsData.append("multipleImg", file);
    });
    createNews(newsData);
  };

  return (
    <div className="container-noticia">
      {createNewsLoading && (
        <Loading progress={progress > 0 && progress != 100 && progress} />
      )}
      {createNewsError && <p>Ocorreu um erro, tente novamente!!!</p>}
      <div className="sub-header-news">
        <h2>Criar Notícia</h2>
        <Button
          className="btn-success"
          variant="primary"
          href="/admin/noticias/painel"
        >
          Voltar para lista
        </Button>
      </div>
      <main>
        <form>
          <label className="form-input">
            Titulo da notícia:
            <input
              type="text"
              name="title"
              className="form-input-news"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
          </label>
          <label className="input-file-container">
            <input
              type="file"
              name="imagem-capa"
              accept="imagem/*"
              className="custom-input-file"
              multiple={false}
              onChange={(e) => setImageCapa(e.target.files[0])}
            />
            {imageCapa !== null && imageCapa !== undefined ? (
              <a
                href={URL.createObjectURL(imageCapa)}
                target="_blank"
                rel="noopener noreferrer"
                title="Visualizar imagem"
                className="img-show-select"
              >
                <img
                  src={URL.createObjectURL(imageCapa)}
                  alt="imagem selecionada"
                />
              </a>
            ) : (
              <Button className="button-selecionar">
                Selecionar Imagem de Capa
              </Button>
            )}
            {imageCapa !== null && imageCapa !== undefined && (
              <Button className="button-selecionar">
                Selecionar Outra Imagem
              </Button>
            )}
          </label>

          <label className="form-input">
            Categoria:
            <select
              defaultValue={1}
              onChange={(e) => setCategoria(e.target.value)}
            >
              {categoryLoading && <option>Carregando...</option>}
              {!categoryLoading &&
                categoryData?.map((item) => (
                  <option value={item.ID} key={item.ID}>
                    {item.NOME}
                  </option>
                ))}
            </select>
          </label>

          <label className="form-input">
            Data:
            <input
              type="date"
              name="data"
              className="input-fit"
              required={true}
              onChange={(e) => setData(e.target.value)}
            />
          </label>

          <label className="form-input checkbox">
            <input
              type="checkbox"
              name="select-visible"
              id="checkbox-visible"
              defaultChecked={true}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <span className="checkmark"></span>
            <p>Status: {isChecked ? "Visível ao público" : "Não Visível"}</p>
          </label>

          <label className="form-input">
            <Editor
              apiKey="khlc1joor43wmnaib9qvcd2kw542nz3v89gokd4n4op2xyeq"
              onEditorChange={handleEditorChange}
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists searchreplace visualblocks wordcount linkchecker",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image  | numlist bullist indent outdent | emoticons charmap | removeformat",
                language: "pt_BR",
              }}
            />
          </label>

          <label className="input-file-container">
            <input
              type="file"
              className="custom-input-file"
              name="multiple_image"
              accept="image/*"
              multiple={true}
              onChange={(e) =>
                setImagensSecundarias(Array.from(e.target.files))
              }
            />
            {imagensSecundarias.length > 0 ? (
              <div className="image-preview-container">
                {imagensSecundarias.map((imagem, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(imagem)}
                    alt={`Imagem ${index + 1}`}
                    className="image-preview"
                  />
                ))}
              </div>
            ) : (
              <Button className="button-selecionar">Selecionar Imagens</Button>
            )}
            {imagensSecundarias.length > 0 &&
              imagensSecundarias !== undefined && (
                <Button className="button-selecionar">
                  Selecionar Outras Imagens
                </Button>
              )}
          </label>
          <Button
            type="submit"
            className="btn-success"
            variant="primary"
            onClick={handleSubmit}
          >
            Enviar notícia
          </Button>
        </form>
      </main>
    </div>
  );
}
