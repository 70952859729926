
import React from "react";
import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import SearchHome from "../../../components/public/others/search/SearchHome";
import styled from "../../../styles/module/others/accessibility.module.css";
import { Table } from "react-bootstrap";

 export default function TextAccessibility() {
    const clickSegment = () =>{
        // eslint-disable-next-line
        if(localStorage.getItem('userway-s3') == 0 ){
          localStorage.setItem('userway-s3', 1)
          document.location.reload();
        }else{
          localStorage.setItem('userway-s3', 0)
          document.location.reload();
    
        }
      }
  return (
    <section className={styled.cardInfor}>
<ol>
    <li><a href="#apresentacao">Apresentação</a></li>
    <li><a href="#ferramentas">Barra de ferramentas</a></li>
    <li ><a href="#como" >Como ativar os atalhos do teclado</a></li>
</ol>

    <div id="conteudo-acessibilidade" className={styled.body}>
        <br/>
        <h5><a name="apresentacao">Apresentação</a></h5>
        <div className={styled.txt}>
        <p>Acessibilidade significa incluir a pessoa com deficiência na participação de atividades como o uso de produtos,
            serviços e informações.</p>
        <p>Em nosso dia-a-dia percebemos as soluções de acessibilidade nas calçadas e nos transportes coletivos adaptados às
            pessoas com deficiências físicas.</p>
        <p>Na internet, acessibilidade refere-se principalmente às recomendações do W3C e no caso do Governo Brasileiro ao
            e-MAG (Modelo de Acessibilidade em Governo Eletrônico). O e-MAG está alinhado as recomendações internacionais,
            mas estabelece padrões de comportamento acessível para sites governamentais.</p><br/>
        </div>

        <h5><a name="ferramentas">Barra de ferramentas</a></h5>
        <div className={styled.txt}>
        <p>Na parte superior deste portal existe uma barra de acessibilidade onde se encontra atalhos de navegação
            padronizados e a opção para alterar o contraste. Essas ferramentas estão disponíveis em todas as páginas do
            portal.</p>
        <ul 
        >
            <li
            ><a accesskey="1" href="#ancoraConteudo"
                    >
                        Ir para conteúdo 1</a> - Salta diretamente para o
                início do conteúdo principal da página.</li>
            <li 
            ><a accesskey="2" href="#ancoraMenu"
                    >Ir para menu 2</a> - Salta diretamente para o início
                do menu principal.</li>
            <li 
            ><a accesskey="3" href="#ancoraRodape"
                    >Ir para rodapé 3</a> - Salta diretamente para o
                rodapé.</li>

            <li><a href="#" accesskey="5" >Acessibilidade 4</a> - Abre a página sobre
                Acessibilidade</li>
                    
        
            <li><a href="#contraste" accesskey="6" id='contraste'
                onClick={()=>{clickSegment() }}   >Alto contraste 5</a> - Ativa e desativa o modo de
                visualização com alto contraste.</li>
        </ul><br/>
        <h5><a name="como" >Como ativar
                os recursos da barra de ferramentas?</a></h5>
        <div><a name="como" ><br/></a></div>
        <div><a name="como" >
            
            <Table responsive>
            <thead>
            <tr>
            
                <th>Ações</th>
                <th>Atalhos</th>
            </tr>
            </thead>
                    <tbody>
                        <tr>
                            <td
    >                            <span 
    >Ação</span></td>
                            <td
    >                            <span >Atalho</span></td>
                        </tr>
                        <tr>
                            <td
    >                            Abrir opções para imprimir a página atual</td>
                            <td
    >                            Ctrl + p</td>
                        </tr>
                        <tr>
                            <td
    >                            Abrir opções para salvar a página atual</td>
                            <td
    >                            Ctrl + s</td>
                        </tr>
                        <tr>
                            <td
    >                            Atualizar a página atual</td>
                            <td
    >                            F5 ou Ctrl + r</td>
                        </tr>
                        <tr>
                            <td
    >                            Atualizar a página atual, ignorando o conteúdo armazenado em cache</td>
                            <td
    >                            Shift + F5 ou Ctrl + Shift + r</td>
                        </tr>
                        <tr>
                            <td
    >                            Interromper o carregamento da página</td>
                            <td
    >                            Esc</td>
                        </tr>
                        <tr>
                            <td
    >                            Navegar por itens clicáveis indo para a frente</td>
                            <td
    >                            Tab</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Navegar por itens clicáveis indo para trás</td>
                            <td
                                >
                                Shift + Tab</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Abrir um arquivo do computador no Chrome</td>
                            <td
                                >
                                Ctrl + o + selecionar um arquivo</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Exibir o código fonte HTML não editável da página atual</td>
                            <td
                                >
                                Ctrl + u</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Salvar a página da Web atual como um favorito</td>
                            <td
                                >
                                Ctrl + d</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Salvar todas as guias abertas como favoritos em uma nova pasta</td>
                            <td
                                >
                                Ctrl + Shift + d</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Ativar ou desativar o modo de tela cheia</td>
                            <td
                                >
                                F11</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Aumentar tudo na página</td>
                            <td
                                >
                                Ctrl e +</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Diminuir tudo na página</td>
                            <td
                                >
                                Ctrl e -</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Retornar tudo na página para o tamanho padrão</td>
                            <td
                                >
                                Ctrl + 0</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Rolar para baixo em uma página da Web, uma tela por vez</td>
                            <td
                                >
                                Espaço ou PgDn</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Rolar para cima em uma página da Web, uma tela por vez</td>
                            <td
                                >
                                Shift + espaço ou PgUp</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Ir para a parte superior da página</td>
                            <td
                                >
                                Home</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Ir para a parte inferior da página</td>
                            <td
                                >
                                Fim</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Rolar a página na horizontal</td>
                            <td
                                >
                                Shift + rolar o mouse</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Mover o cursor para o início da palavra anterior em um campo de texto</td>
                            <td
                                >
                                Ctrl + seta para a esquerda</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Mover o cursor para a próxima palavra</td>
                            <td
                                >
                                Ctrl + seta para a direita</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Excluir a palavra anterior em um campo de texto</td>
                            <td
                                >
                                Ctrl + Backspace</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Abrir a página inicial na guia atual</td>
                            <td
                                >
                                Alt + Home</td>
                        </tr>
                        <tr>
                            <td
                                >
                                Redefinir o nível de zoom da página</td>
                            <td
                                >
                                Ctrl + 0</td>
                        </tr>
                    </tbody>
                </Table>


            </a></div>

            <div className={styled.txt}>
        <p>Para utilizar os recursos descritos acima você pode usar a barra de ferramentas presente no topo de todas as
            páginas do portal, basta clicar diretamente sobre a opção desejada.</p>
        <p>Se você preferir, utilize os atalhos do teclado conforme o navegador que você está usando:</p>
        <ul>
            <li>No <strong>Internet Explorer, Safari, Opera, e Chrome</strong> os atalho
                são ativados com <b>ALT + numero do recurso</b>. Por exemplo para <i>"Ir para o conteúdo"</i> utilize <b>ALT
                    + 1</b>.</li>
            <li>No <strong>Firefox</strong> os atalho são ativados com <b>SHIFT + ALT + o
                    numero do recurso</b>.</li>
            <li>Em todos navegadores para <strong>Mac OS</strong> os comandos são
                ativados com <b>CONTROL + ALT + número do recurso</b>.</li>
        </ul>
        </div>
            
    </div>
    </div>
    </section>
  );

}

