import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import CardOffice from "../../../components/public/office/OfficeInfo"
import Footer from "../../../components/public/others/Footer";
import { TipoPortal } from "../../../services/variables";


export default function OfficePage() {

 
 
  return (

    <>  
    {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
    {TipoPortal == 3 ? <>
      <NavMenu/>    
    </>:<>
      <NavMenuCM/>
    </>}
      <CardOffice/>
      <Footer></Footer>
    </>
  )
}

