import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import { TipoPortal } from '../../../../services/variables';
import MenuSalaEmpreendedor from '../../../../components/public/transparency/salaEmpreendedor/MenuEmpreendedor';
import SubTitle from './../../../../components/public/others/title/SubTitle';

export default function homeSalaEmpreendedor(props) {
    const route = props.route
  
    return (
      <> 
       {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
   {TipoPortal == 3 ? <>
        <NavMenu/>
          </>:<>
        <NavMenuCM/>
        </>}
        <div className="section-title rotes_pages">
        <h6>Início {route}</h6>
        </div>
        <div className="section-title-portarias">
        <h4>Sala Empreendedor</h4>
        </div>
        <MenuSalaEmpreendedor filter={'Início'}/>
        <SubTitle subTitle={'Conheça a Sala do Empreendedor Digital'} border={true}/>
        <div className="section-title">
        <p>
        A Sala do Empreendedor Digital foi criada com o objetivo de facilitar o acesso aos serviços voltados aos empreendedores ou futuros empreendedores. Preparamos esse ambiente digital onde você poderá fazer facilmente a abertura da sua empresa, alteração cadastral, solicitação de alvará, emissão de nota fiscal, baixa de empresa e outros serviços em um só lugar e com a facilidade do mundo digital.
        </p>
        </div>
        <br/>
        <Footer/>
        
      </>)}
