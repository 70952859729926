import styled from "../../../../styles/module/transparency/salaEmpreendedor/servicesButton.module.css"
import Buttons from "./json/buttonsFerramentas.js"

export default function ButtonsTools(props) {
    

    return(<>
    <section className={styled.gridPage}id='buttonsServices'>

    
    <div className={styled.gridEmpreendedor} >


        {Buttons.map((i,key)=>{
            
            return(
                <>
                {i.link == '#' ? 
<a href={i.link} title={i.name} className={styled.btnServicosEmpreendedor}>{i.name}</a>
            :
<a href={i.link}  target="_blank" title={i.name} className={styled.btnServicosEmpreendedor}>{i.name}</a>
            }
                </>
           )})}
    </div>
   
    </section>
    </>)
}