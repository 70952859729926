import { NomePortal, NomeTipoPortal } from "../../../../../services/variables";



const menu = [
    {
        img:`/imagens/salaEmpreendedor/sala-empreendedor-fundo (0).jpg`,
        title:`Início`,
        body:`Voltar para página inicial da sala do empreendedor`,
        link:`/transparencia/sala-empreendedor`,
    },
    {
        img:`/imagens/salaEmpreendedor/sala-empreendedor-fundo (1).jpg`,
        title:`MEI`,
        body:`O MEI é uma pessoa jurídica sem complicações e com diversos benefícios para o empreendedor`,
        link:`/transparencia/sala-empreendedor/mei`,
    },
    {
        img:`/imagens/salaEmpreendedor/sala-empreendedor-fundo (2).jpg`,
        title:`Sala Digital`,
        body:`Aqui você encontra tudo que é necessário para criar e gerenciar as informações da sua empresa`, 
        link:`/transparencia/sala-empreendedor/sala-digital`,
    },
    {
        img:`/imagens/salaEmpreendedor/sala-empreendedor-fundo (3).jpg`,
        title:`Ferramentas de gestão`,
        body:`As melhores ferramentas para gerenciar e otimizar a sua empresa`,
        link:`/transparencia/sala-empreendedor/ferramentas-de-gestao`,
    },
    {
        img:`/imagens/salaEmpreendedor/sala-empreendedor-fundo (4).jpg`,
        title:`${NomeTipoPortal} ${NomePortal}`,
        body:`Encontre licitações e contratos na Instituição`,
        link:`/licitacoes-e-contratos/edital`,

    },
]
export default menu;



