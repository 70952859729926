export default function SubTitle(props){
    const {subTitle, border} = props;
    return(
        
    <div className="section-title">
        <div className="cardTitle">
    
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"  className="icon-title"  viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
        <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    <h5>
    {/* <img
    className="icon-title"
    alt="Links Rápidos"
    src="/icons/seta-direita.svg"
/>  */}
    {subTitle}
    </h5>
  
    </div>
    {border && 
    <div className="border"></div>
    }
    </div>


    )
}