




const buttonsServices = [
    {
    name:`ABERTURA DE EMPRESA`,
    link:`https://www.gov.br/empresas-e-negocios/pt-br`,
    },
   {
    name:`ALTERAÇÃO CADASTRAL`,
    link:`https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/servicos-para-mei/atualizacao-cadastral-de-mei`,
   },
   {
    name:`DECLARAÇÃO ANUAL`,
    link:`https://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/dasnsimei.app/Identificacao`,
   },
   {
    name:`EMISSÃO DE NOTA FISCAL`,
    link:`https://www.gov.br/empresas-e-negocios/pt-br`,
   },
   {
    name:`ALVARÁS E LICENÇAS`,
    link:`https://stm.semfaz.saoluis.ma.gov.br/sistematributario/jsp/login/login.jsf`,
   },
   {
    name:`EMISSÃO DE DAS MEI`,
    link:`https://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/pgmei.app/Identificacao`,
   },
   {
    name:`BAIXAR CNPJ`,
    link:`https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/servicos-para-mei`,
   },
   {
    name:`CONSULTORIAS GRATUITAS`,
    link:`#`,
   },
   {
    name:`CERTIDÕES DE REGULARIDADE`,
    link:`https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/servicos-para-mei/emissao-de-comprovante-ccmei`,
   },
   {
    name:`REGULARIZAÇÃO E PARCELAMENTOS DE DÉBITOS`,
    link:`https://www8.receita.fazenda.gov.br/SimplesNacional/Servicos/Grupo.aspx?grp=19`,
   }, 
]
export default buttonsServices;



